import axios from "axios";
import Constants from "../constants/constants";

// import history from "../constants/history";
// import * as LocalStorage from "../constants/localStorage";
const apiPrefix = process.env.VUE_APP_BASE_NEW_URL;
const apiTerms = process.env.VUE_APP_TERMS_API_URL;

function baseApi() {
  return axios.create({
    baseURL: apiPrefix,
    timeout: 5000,
    headers: {
      "Content-Type": "application/json",
    },
  });
}

function termsApi() {
  return axios.create({
    baseURL: apiTerms,
    timeout: 5000,
    headers: {
      "Content-Type": "application/json",
    },
  });
}

function successStatusCheck(response, resolve) {
  if (response.status === 200) {
    resolve(response);
  } else if (response.status === 401) {
    moveMain();
  } else {
    resolve("error");
  }
}

function failStatusCheck(err, reject) {
  if (err.response && err.response.status === 401) {
    moveMain();
  } else {
    reject(err);
  }
}

function moveMain() {
  // LocalStorage.clear();
  history.push("/main");
}

// 커뮤니티 탭 클릭 시 리스트 호출
export function getList(category, tag, queryStr) {
  return new Promise((resolve, reject) => {
    return baseApi()
      .get(`/${category}/${tag}${queryStr}`)
      .then((response) => {
        successStatusCheck(response, resolve);
      })
      .catch((err) => {
        failStatusCheck(err, reject);
      });
  });
}

// 디테일 호출
export function getDetail(category, tag, id) {
  return new Promise((resolve, reject) => {
    return baseApi()
      .get(`/hp/${category}/${tag}/${id}`)
      .then((response) => {
        successStatusCheck(response, resolve);
      })
      .catch((err) => {
        failStatusCheck(err, reject);
      });
  });
}

// 약관 호출
export function getTermsProtectorVersion() {
  return new Promise((resolve, reject) => {
    return termsApi(apiTerms)
      .get(Constants.TERMS_PROTECTOR_API)
      .then((response) => {
        successStatusCheck(response, resolve);
      })
      .catch((err) => {
        failStatusCheck(err, reject);
      });
  });
}

export function getPolicyProtectorVersion() {
  return new Promise((resolve, reject) => {
    return termsApi(apiTerms)
      .get(Constants.POLICY_PROTECTOR_API)
      .then((response) => {
        successStatusCheck(response, resolve);
      })
      .catch((err) => {
        failStatusCheck(err, reject);
      });
  });
}

export function getTermsCaregiverVersion() {
  return new Promise((resolve, reject) => {
    return termsApi(apiTerms)
      .get(Constants.TERMS_CAREGIVER_API)
      .then((response) => {
        successStatusCheck(response, resolve);
      })
      .catch((err) => {
        failStatusCheck(err, reject);
      });
  });
}

export function getPolicyCaregiverVersion() {
  return new Promise((resolve, reject) => {
    return termsApi(apiTerms)
      .get(Constants.POLICY_CAREGIVER_API)
      .then((response) => {
        successStatusCheck(response, resolve);
      })
      .catch((err) => {
        failStatusCheck(err, reject);
      });
  });
}

export function getTermsLocationService() {
  return new Promise((resolve, reject) => {
    return termsApi(apiTerms)
      .get(Constants.TERMS_LOCATION_API)
      .then((response) => {
        successStatusCheck(response, resolve);
      })
      .catch((err) => {
        failStatusCheck(err, reject);
      });
  });
}

export function getTerms(termsId) {
  return new Promise((resolve, reject) => {
    return termsApi(apiTerms)
      .get(`${Constants.TERMS_API}/${termsId}`)
      .then((response) => {
        successStatusCheck(response, resolve);
      })
      .catch((err) => {
        failStatusCheck(err, reject);
      });
  });
}

export function getPolicy(policyId) {
  return new Promise((resolve, reject) => {
    return termsApi(apiTerms)
      .get(`${Constants.POLICY_API}/${policyId}`)
      .then((response) => {
        successStatusCheck(response, resolve);
      })
      .catch((err) => {
        failStatusCheck(err, reject);
      });
  });
}

export default {
  getList,
  getDetail,
  getTermsProtectorVersion,
  getPolicyProtectorVersion,
  getTermsCaregiverVersion,
  getPolicyCaregiverVersion,
  getTerms,
  getPolicy,
};
