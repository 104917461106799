<template>
  <main>
    <article class="subBan ban06">
      <div class="subBan__wrap">
        <h2>케어네이툰</h2>
        <p>환자와 간병인의 이야기, 웹툰으로 만나보세요</p>
      </div>
    </article>
    <section class="commuDet">
      <div class="basicWrap">
        <div class="commuDet__tit clear">
          <span>현재글</span>
          <div>
            <h3>
              <small>{{ toonList[contId].episode }}화</small>
              {{ toonList[contId].title }}
            </h3>
          </div>
        </div>
        <div class="commuDet__con">
          <figure class="commuDet__con--img">
            <img
              v-for="(detCont, contIndex) in toonList[contId].content_img"
              :key="contIndex"
              :src="detCont[0]"
              :alt="detCont[1]"
            />
          </figure>
        </div>
        <div class="commuDet__list">
          <div
            class="commuDet__list--next clear"
            :class="toonList[parseInt(contId) + 1] == null ? 'disabled' : ''"
            :disabled="toonList[parseInt(contId) + 1] == null"
            @click="itemNext()"
          >
            <span>다음글</span>
            <h2 v-if="toonList[parseInt(contId) + 1] == null">
              다음 글이 없습니다.
            </h2>
            <h2 v-if="toonList[parseInt(contId) + 1] !== null">
              {{
                parseInt(contId) + 1 < 16
                  ? toonList[parseInt(contId) + 1].title
                  : ""
              }}
            </h2>
          </div>
          <div
            class="commuDet__list--prev clear"
            :class="toonList[contId - 1] == null ? 'disabled' : ''"
            :disabled="toonList[contId - 1] == null"
            @click="itemPrev()"
          >
            <span>이전글</span>
            <h2 v-if="toonList[contId - 1] == null">이전 글이 없습니다.</h2>
            <h2 v-if="toonList[contId - 1] !== null">
              {{ contId - 1 >= 0 ? toonList[contId - 1].title : "" }}
            </h2>
          </div>
          <div class="commuDet__list--btn clear">
            <router-link
              :to="{
                name: 'commuToon',
                query: { page: this.$route.query.page },
              }"
              >목록</router-link
            >
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import * as Utils from "../../constants/utils";
import toonList from "@/assets/js/toonList";
export default {
  data() {
    return {
      toonList: toonList, // 데이터
      contId: this.$route.query.contId, // 컨텐츠 번호
      limit: Utils.osType() === "mobile" ? 8 : 12, // pc일때 페이지당 9개, 모바일일 때 8개 표시
    };
  },
  methods: {
    itemNext() {
      if (this.contId < 15) {
        if (this.contId < 16 - this.limit) {
          this.contId = parseInt(this.contId) + 1;
          this.$router.push({
            name: "commuToonDet",
            query: {
              contId: parseInt(this.contId),
              page: 1,
            },
          });
        } else {
          this.contId = parseInt(this.contId) + 1;
          this.$router.push({
            name: "commuToonDet",
            query: {
              contId: parseInt(this.contId),
              page: 2,
            },
          });
        }
      } else {
        // console.log("범위값 초과");
      }
    },
    itemPrev() {
      if (this.contId > 0) {
        if (this.contId > 16 - this.limit) {
          this.contId = parseInt(this.contId) - 1;
          this.$router.push({
            name: "commuToonDet",
            query: {
              contId: parseInt(this.contId),
              page: 1,
            },
          });
        } else {
          this.contId = parseInt(this.contId) - 1;
          this.$router.push({
            name: "commuToonDet",
            query: {
              contId: parseInt(this.contId),
              page: 2,
            },
          });
        }
      }
    },
  },
};
</script>

<style></style>
