<template>
  <header :class="{ open: subMenu }">
    <div class="headerWrap clear">
      <h1>
        <router-link to="/">
          <img src="@/assets/img/img_logo01.png" alt="케어네이션" />
        </router-link>
      </h1>
      <button type="button" class="mobileBtn" @click="subMenu = !subMenu">전체 메뉴</button>
      <nav>
        <ul
          class="headerWrap__menu clear"
          @mouseover="handleMouseOver"
          @mouseout="handleMouseOut"
          :class="{ disabled: isMobile && !subMenu }"
        >
          <li @click="activeOn(1), gnbOff()">
            <router-link
              :to="{ path: '/dictionary', query: { page: 1 } }"
              class="depth1"
              :class="isActive == 1 ? 'active' : ''"
              >간병백과</router-link
            >
          </li>
          <li @click="activeOn(2)">
            <router-link
              :to="{ path: '/story', query: { page: 1, tag: '간병꿀팁' } }"
              class="depth1 pc"
              :class="isActive == 2 ? 'active' : ''"
              >간병이야기</router-link
            >
            <a class="depth1 mobile" :class="{ active: mobMenu == 1 }" @click="toggleMenu(1)"
              >간병이야기</a
            >
            <ul class="depth2">
              <li @click="gnbOff()">
                <router-link
                  :to="{ path: '/story', query: { page: 1, tag: '간병꿀팁' } }"
                  :class="mobSubMenu == 1 ? 'active' : ''"
                  >간병꿀팁</router-link
                >
              </li>
              <li @click="gnbOff()">
                <router-link
                  :to="{
                    path: '/story',
                    query: { page: 1, tag: '신규 간병 안내서' },
                  }"
                  :class="mobSubMenu == 2 ? 'active' : ''"
                  >신규 간병 안내서</router-link
                >
              </li>
              <li @click="gnbOff()">
                <router-link
                  :to="{
                    path: '/story',
                    query: { page: 1, tag: '입문가이드' },
                  }"
                  :class="mobSubMenu == 3 ? 'active' : ''"
                  >입문 가이드</router-link
                >
              </li>
              <li @click="gnbOff()">
                <router-link
                  :to="{
                    path: '/story',
                    query: { page: 1, tag: '일자리정보' },
                  }"
                  :class="mobSubMenu == 4 ? 'active' : ''"
                  >일자리 정보</router-link
                >
              </li>
              <li @click="gnbOff()">
                <router-link
                  :to="{
                    path: '/story',
                    query: { page: 1, tag: '궁금해요간병' },
                  }"
                  :class="mobSubMenu == 5 ? 'active' : ''"
                  >궁금해요간병</router-link
                >
              </li>
              <li @click="gnbOff()">
                <router-link
                  :to="{
                    path: '/story',
                    query: { page: 1, tag: '보호자후기' },
                  }"
                  :class="mobSubMenu == 6 ? 'active' : ''"
                  >보호자 후기</router-link
                >
              </li>
              <li @click="gnbOff()">
                <router-link
                  :to="{ path: '/story', query: { page: 1, tag: '헬스케어' } }"
                  :class="mobSubMenu == 7 ? 'active' : ''"
                  >헬스케어</router-link
                >
              </li>
              <li @click="gnbOff()">
                <router-link
                  :to="{ path: '/story', query: { page: 1, tag: '간병정보' } }"
                  :class="mobSubMenu == 8 ? 'active' : ''"
                  >간병정보</router-link
                >
              </li>
              <li @click="gnbOff()">
                <router-link
                  :to="{
                    path: '/story',
                    query: { page: 1, tag: '간병인 인터뷰' },
                  }"
                  :class="mobSubMenu == 9 ? 'active' : ''"
                  >간병인 인터뷰</router-link
                >
              </li>
              <li @click="gnbOff()">
                <router-link
                  :to="{ path: '/story', query: { page: 1, tag: '간병탐구' } }"
                  :class="mobSubMenu == 10 ? 'active' : ''"
                  >간병 탐구</router-link
                >
              </li>
            </ul>
          </li>
          <li @click="activeOn(3), gnbOff()">
            <router-link
              :to="{ path: '/xFile', query: { page: 1, tag: '' } }"
              class="depth1"
              :class="isActive == 3 ? 'active' : ''"
              >간병 사고 X파일</router-link
            >
          </li>
          <li @click="activeOn(4)">
            <router-link
              :to="{ path: '/news', query: { page: 1, tag: '언론소식' } }"
              class="depth1 pc"
              :class="isActive == 4 ? 'active' : ''"
              >케어네이션 뉴스</router-link
            >
            <a class="depth1 mobile" :class="{ active: mobMenu == 2 }" @click="toggleMenu(2)"
              >케어네이션 뉴스</a
            >
            <ul class="depth2">
              <li @click="gnbOff()">
                <router-link
                  :to="{ path: '/news', query: { page: 1, tag: '언론소식' } }"
                  :class="mobSubMenu == 11 ? 'active' : ''"
                  >언론 소식</router-link
                >
              </li>
              <li @click="gnbOff()">
                <router-link
                  :to="{ path: '/news', query: { page: 1, tag: '공지' } }"
                  :class="mobSubMenu == 12 ? 'active' : ''"
                  >공지</router-link
                >
              </li>
              <li @click="gnbOff()">
                <router-link
                  :to="{ path: '/news', query: { page: 1, tag: '캠페인' } }"
                  :class="mobSubMenu == 13 ? 'active' : ''"
                  >캠페인</router-link
                >
              </li>
              <li @click="gnbOff()">
                <router-link
                  :to="{ path: '/news', query: { page: 1, tag: '인터뷰' } }"
                  :class="mobSubMenu == 14 ? 'active' : ''"
                  >인터뷰</router-link
                >
              </li>
            </ul>
          </li>
          <li @click="activeOn(5)">
            <router-link
              :to="{ path: '/info', query: { page: 1, tag: '케어 매거진' } }"
              class="depth1 pc"
              :class="isActive == 5 ? 'active' : ''"
              >건강 정보</router-link
            >
            <a class="depth1 mobile" :class="{ active: mobMenu == 3 }" @click="toggleMenu(3)"
              >건강 정보</a
            >
            <ul class="depth2">
              <li @click="gnbOff()">
                <router-link
                  :to="{
                    path: '/info',
                    query: { page: 1, tag: '케어 매거진' },
                  }"
                  :class="mobSubMenu == 15 ? 'active' : ''"
                  >케어 매거진</router-link
                >
              </li>
              <li @click="gnbOff()">
                <router-link
                  :to="{ path: '/info', query: { page: 1, tag: '질환 정보' } }"
                  :class="mobSubMenu == 16 ? 'active' : ''"
                  >질환 정보</router-link
                >
              </li>
            </ul>
          </li>
          <li @click="activeOn(6), gnbOff()">
            <router-link
              :to="{ path: '/toon', query: { page: 1 } }"
              class="depth1"
              :class="isActive == 6 ? 'active' : ''"
              >케어네이툰</router-link
            >
          </li>
        </ul>
      </nav>
    </div>
  </header>
</template>

<script>
export default {
  name: "commonHeader",
  components: {},
  data() {
    return {
      isActive: 0, //## 1depth active
      subMenu: false, //## gnb창 오픈
      isMobile: false, //## 모바일 환경 여부 체크
      mobMenu: 0,
      mobSubMenu: 0,
    };
  },
  created() {
    this.setInitialRouteState();
  },
  methods: {
    // 페이지 이동
    activeOn(index) {
      this.isActive = index;
    },
    // 모바일 사이즈일 땐 mouseover, mouseout 동작 방지
    handleMouseOver() {
      if (!this.isMobile) {
        this.subMenu = true;
      }
    },
    handleMouseOut() {
      if (!this.isMobile) {
        this.subMenu = false;
      }
    },
    checkWindowSize() {
      this.isMobile = window.innerWidth <= 1023;
    },
    // gnb off
    gnbOff() {
      this.subMenu = false;
    },
    // 모바일 서브메뉴 토글
    toggleMenu(index) {
      if (this.mobMenu == index) {
        this.mobMenu = 0;
      } else {
        this.mobMenu = index;
      }
    },
    // 라우터 이동 감지 (gnb active 부여용)
    setInitialRouteState() {
      if (
        this.$route.path == "/" ||
        this.$route.path == "/terms" ||
        this.$route.path == "/dictionary" ||
        this.$route.path == "/dictionary/detail" ||
        this.$route.path == "/xFile" ||
        this.$route.path == "/xFile/detail" ||
        this.$route.path == "/toon" ||
        this.$route.path == "/toon/detail"
      ) {
        this.mobSubMenu = 0;
      } else if (this.$route.path == "/story" || this.$route.path == "/story/detail") {
        this.mobMenu = 1;
        switch (this.$route.query.tag) {
          case "간병꿀팁":
            this.mobSubMenu = 1;
            break;
          case "신규 간병 안내서":
            this.mobSubMenu = 2;
            break;
          case "입문가이드":
            this.mobSubMenu = 3;
            break;
          case "일자리정보":
            this.mobSubMenu = 4;
            break;
          case "궁금해요간병":
            this.mobSubMenu = 5;
            break;
          case "보호자후기":
            this.mobSubMenu = 6;
            break;
          case "헬스케어":
            this.mobSubMenu = 7;
            break;
          case "간병정보":
            this.mobSubMenu = 8;
            break;
          case "간병인 인터뷰":
            this.mobSubMenu = 9;
            break;
          case "간병탐구":
            this.mobSubMenu = 10;
            break;
        }
      } else if (this.$route.path == "/news" || this.$route.path == "/news/detail") {
        this.mobMenu = 2;
        switch (this.$route.query.tag) {
          case "언론소식":
            this.mobSubMenu = 11;
            break;
          case "공지":
            this.mobSubMenu = 12;
            break;
          case "캠페인":
            this.mobSubMenu = 13;
            break;
          case "인터뷰":
            this.mobSubMenu = 14;
            break;
        }
      } else if (this.$route.path == "/info" || this.$route.path == "/info/detail") {
        this.mobMenu = 3;
        switch (this.$route.query.tag) {
          case "케어 매거진":
            this.mobSubMenu = 15;
            break;
          case "질환 정보":
            this.mobSubMenu = 16;
            break;
        }
      }
    },
  },
  watch: {
    $route() {
      this.setInitialRouteState();
    },
  },
  mounted() {
    // 컴포넌트가 마운트될 때와 창 크기가 변경될 때마다 실행되는 이벤트 핸들러를 등록
    window.addEventListener("resize", this.checkWindowSize);
    this.checkWindowSize();
  },
  beforeDestroy() {
    // 컴포넌트가 파괴되기 전에 이벤트 핸들러를 제거
    window.removeEventListener("resize", this.checkWindowSize);
  },
};
</script>
