<template>
  <div>
    <commonHeader />
    <router-view />
    <commonFooter />
  </div>
</template>

<script>
import commonHeader from "@/components/commonLayout/commonHeader";
import commonFooter from "@/components/commonLayout/commonFooter";
export default {
  name: "App",
  components: {
    commonHeader: commonHeader,
    commonFooter: commonFooter,
  },
};
</script>
