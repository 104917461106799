var render = function render(){var _vm=this,_c=_vm._self._c;return _c('main',[_vm._m(0),_c('section',{staticClass:"commu"},[_c('div',{staticClass:"basicWrap"},[_c('ul',{staticClass:"commu__list clear"},_vm._l((_vm.listData.data.list),function(listItem,itemIndex){return _c('li',{key:itemIndex,on:{"click":function($event){_vm.indexSave(itemIndex), _vm.enterDetail(listItem.id)}}},[_c('a',{class:listItem.type == 'youtube' ? 'video' : 'post',attrs:{"href":""}},[_c('figure',{style:({
                backgroundImage:
                  'url(https://s3.ap-northeast-2.amazonaws.com/img.carenation.kr/' +
                  listItem.thumbnail +
                  ')',
              })}),_c('div',{staticClass:"commu__list--tit"},[_c('p',[_vm._v(_vm._s(listItem.title))])])])])}),0),_c('div',{staticClass:"commu__btn clear"},[(this.curPage > this.pageSize)?_c('button',{staticClass:"prev",attrs:{"type":"button"},on:{"click":function($event){return _vm.pagePrev()}}},[_vm._v(" 이전 ")]):_vm._e(),_vm._l((_vm.pageNum),function(pageItem){return _c('button',{key:pageItem,class:_vm.$route.query.page == pageItem ? 'active' : '',attrs:{"type":"button"},on:{"click":function($event){return _vm.pageChange(pageItem)}}},[_vm._v(" "+_vm._s(pageItem)+" ")])}),(this.realendPage < this.totalPage)?_c('button',{staticClass:"next",attrs:{"type":"button"},on:{"click":function($event){return _vm.pageNext()}}},[_vm._v(" 다음 ")]):_vm._e()],2)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('article',{staticClass:"subBan ban01"},[_c('div',{staticClass:"subBan__wrap"},[_c('h2',[_vm._v("간병백과")]),_c('p',[_vm._v("초보 보호자/간병인을 위한 필수 간병 가이드")])])])
}]

export { render, staticRenderFns }