// # 케어네이툰 컨텐츠
export default [
  {
    episode: 0,
    title: "케어네이션 X 연두",
    thumbnail: require("@/assets/img/img_careToon00-1.jpg"),
    content_img: [
      [
        require("@/assets/img/img_careToon00-1.jpg"),
        "다양한 간병인 여사님들을 거쳐간 연두",
      ],
      [
        require("@/assets/img/img_careToon00-2.jpg"),
        "마음 고생을 줄여주는 간병인 매칭 플랫폼",
      ],
      [require("@/assets/img/img_careToon00-3.jpg"), "바로 케어네이션"],
      [
        require("@/assets/img/img_careToon00-4.jpg"),
        "프로필과 후기, 평점을 보고 간병인 선택",
      ],
      [
        require("@/assets/img/img_careToon00-5.jpg"),
        "입찰제 방식으로 간병비 비교 선택 가능",
      ],
      [require("@/assets/img/img_careToon00-6.jpg"), "부담없는 카드 결제"],
      [
        require("@/assets/img/img_careToon00-7.jpg"),
        "간병인들도 간병일감을 직접 선택하고 간병비도 직접 제시",
      ],
      [
        require("@/assets/img/img_careToon00-8.jpg"),
        "오늘 일하면 내일 바로 입금",
      ],
      [
        require("@/assets/img/img_careToon00-9.jpg"),
        "보다 좋은 간병문화를 만드는 케어네이션",
      ],
      [require("@/assets/img/img_careToon00-10.jpg"), "연두가 강력 추천해요"],
    ],
  },
  {
    episode: 1,
    title: "사고 전의 연두",
    thumbnail: require("@/assets/img/img_careToon01-1.jpg"),
    content_img: [
      [
        require("@/assets/img/img_careToon01-1.jpg"),
        "연두는 대학 때 신소재공학을 전공",
      ],
      [
        require("@/assets/img/img_careToon01-2.jpeg"),
        "문과 성향이던 연두는 공부가 정말 힘들었다",
      ],
      [
        require("@/assets/img/img_careToon01-3.jpeg"),
        "졸업은 했지만 내가 좋아하던 여행 관련 일을 하고 싶었고",
      ],
      [require("@/assets/img/img_careToon01-4.jpeg"), "여행사에 취업했다"],
      [
        require("@/assets/img/img_careToon01-5.jpeg"),
        "좋은 사람들을 만나 업무 배우는 것이 재밌었다",
      ],
      [
        require("@/assets/img/img_careToon01-6.jpeg"),
        "코로나가 심해지면서 정리해고 당했고",
      ],
      [require("@/assets/img/img_careToon01-7.jpeg"), "회사는 폐업하고 말았다"],
      [
        require("@/assets/img/img_careToon01-8.jpeg"),
        "집 사정이 원래 좋지 않아 첫 취업은 물거품으로 돌아가고",
      ],
      [
        require("@/assets/img/img_careToon01-9.jpeg"),
        "낙상 사고까지 겪고 말았다",
      ],
      [
        require("@/assets/img/img_careToon01-10.jpeg"),
        "그렇게 연두의 삶도 물거품처럼 사라지는 것인줄 알았다",
      ],
    ],
  },
  {
    episode: 2,
    title: "사고 직후의 생각들 (1)",
    thumbnail: require("@/assets/img/img_careToon02-1.jpg"),
    content_img: [
      [
        require("@/assets/img/img_careToon02-1.jpg"),
        "눈을 떴을 때는 수술이 이미 다 끝난 후였다",
      ],
      [
        require("@/assets/img/img_careToon02-2.jpeg"),
        "온몸이 다쳐서 아프고 움직일 수도 없었다",
      ],
      [
        require("@/assets/img/img_careToon02-3.jpeg"),
        "다리가 전혀 움직이지 않았고 하반신 마비가 된 것이다",
      ],
      [
        require("@/assets/img/img_careToon02-4.jpeg"),
        "갈비뼈, 골반, 척추, 흉골이 골절되었고",
      ],
      [
        require("@/assets/img/img_careToon02-5.jpeg"),
        "폐에 구멍을 뚫기도 했고, 산소줄을 달고 다니게 됐다",
      ],
      [
        require("@/assets/img/img_careToon02-6.jpeg"),
        "하루에도 몇번찍 침대째로 이동해 엑스레이를 찍고 여러 검사를 받았다",
      ],
      [
        require("@/assets/img/img_careToon02-7.jpeg"),
        "사고 전에는 누구나 후천적으로 장애가 생길 수 있다고 생각했지만",
      ],
      [
        require("@/assets/img/img_careToon02-8.jpeg"),
        "그것이 제 일이 될 줄은 정말 몰랐다",
      ],
      [require("@/assets/img/img_careToon02-9.jpeg"), "좌절스러웠다"],
      [
        require("@/assets/img/img_careToon02-10.jpeg"),
        "나는 어떡해야하는거지...",
      ],
    ],
  },
  {
    episode: 3,
    title: "사고 직후의 생각들 (2)",
    thumbnail: require("@/assets/img/img_careToon03-1.jpg"),
    content_img: [
      [
        require("@/assets/img/img_careToon03-1.jpg"),
        "다쳐서 하반신이 마비가 되었어도 시간은 똑같이 흘러갔다",
      ],
      [
        require("@/assets/img/img_careToon03-2.jpg"),
        "중환자실에서 매 끼니 한 두 숟갈의 미음을 먹으며 살이 엄청 빠졌고",
      ],
      [
        require("@/assets/img/img_careToon03-3.jpg"),
        "시간이 지나며 조금씩 움직일 수 있게 되었지만 작은 움직임에도 사람들이 놀랄 정도로 많이 다쳤다",
      ],
      [
        require("@/assets/img/img_careToon03-4.jpg"),
        "그래서 매 30분마다 간호사 선생님들께서 욕창을 막기 위해 체위 변경을 도와주셨다",
      ],
      [
        require("@/assets/img/img_careToon03-5.jpg"),
        "그리고 간병인 이모를 처음 만나게 되었다",
      ],
      [
        require("@/assets/img/img_careToon03-6.jpg"),
        "간병인 이모와 생활해가며 점차 몸이 회복되기 시작했다",
      ],
      [
        require("@/assets/img/img_careToon03-7.jpg"),
        "회복이 생각보다 빨랐기 때문에 2차 수술을 하게 되었다",
      ],
      [
        require("@/assets/img/img_careToon03-8.jpg"),
        "1차 수술보다 빠른 회복세가 보였고 휠체어도 처음으로 타게 되었다",
      ],
      [require("@/assets/img/img_careToon03-9.jpg"), "그렇게 점차 나아지면서"],
      [
        require("@/assets/img/img_careToon03-10.jpg"),
        "본격적으로 재활병원으로 전원을 앞두게 되었다",
      ],
    ],
  },
  {
    episode: 4,
    title: "치료에서 긍정적인 자세를 갖는 법 (1)",
    thumbnail: require("@/assets/img/img_careToon04-1.jpg"),
    content_img: [
      [
        require("@/assets/img/img_careToon04-1.jpg"),
        "재활병원으로 온 뒤에 본격적으로 재활을 받게 되었다",
      ],
      [
        require("@/assets/img/img_careToon04-2.jpeg"),
        "매일 매일 다양한 치료가 이어졌다",
      ],
      [
        require("@/assets/img/img_careToon04-3.jpeg"),
        "새로 받는 치료들을 기록하고, 마음을 다잡기위해 일기를 쓰기 시작했다",
      ],
      [
        require("@/assets/img/img_careToon04-4.jpeg"),
        "일기를 매일 기록하다보니 감사한 마음이 솟구쳤다",
      ],
      [
        require("@/assets/img/img_careToon04-5.jpeg"),
        "비록 하반신이 마비가 되었지만 저는 아직 손을 쓸 수 있고, 재활을 받다 보면 나아질 수도 있으니까요!",
      ],
      [
        require("@/assets/img/img_careToon04-6.jpeg"),
        "반복되는 생활에 지치지 않고 적응하기 위해 취미생활도 시작했다",
      ],
      [
        require("@/assets/img/img_careToon04-7.jpeg"),
        "강의 보면서 뜨개질 배우기",
      ],
      [
        require("@/assets/img/img_careToon04-8.jpeg"),
        "책을 읽거나 재미있는 드라마 보기",
      ],
      [require("@/assets/img/img_careToon04-9.jpeg"), "그림 그리기 등..."],
      [
        require("@/assets/img/img_careToon04-10.jpeg"),
        "그러던 중, 내가 받는 재활 생활을 그림으로 기록한다면 어떨까 하는 생각이 들었다",
      ],
    ],
  },
  {
    episode: 5,
    title: "치료에서 긍정적인 자세를 갖는 법 (2)",
    thumbnail: require("@/assets/img/img_careToon05-1.jpeg"),
    content_img: [
      [
        require("@/assets/img/img_careToon05-1.jpeg"),
        "노트에 만화로 재활일기를 그리기 시작했다",
      ],
      [
        require("@/assets/img/img_careToon05-2.jpeg"),
        "치료사 선생님들께서 만화를 보시고 SNS에 만화를 업로드해보라고 제안하셨다",
      ],
      [
        require("@/assets/img/img_careToon05-3.jpeg"),
        "제 이야기가 누군가에게 도움이 되기를 바라며 만화를 올려보기 시작했다",
      ],
      [
        require("@/assets/img/img_careToon05-4.jpeg"),
        "그리고 가족과도 매일 매일 연락했고",
      ],
      [
        require("@/assets/img/img_careToon05-5.jpeg"),
        "치료사 선생님들과 친하게 지내며 대화도 많이 나눴다",
      ],
      [
        require("@/assets/img/img_careToon05-6.jpeg"),
        "소소한 기분전환도 자주 하며 긍정적인 자세를 가지려 노력했고",
      ],
      [
        require("@/assets/img/img_careToon05-7.jpeg"),
        "친구들의 응원도 많이 받았다",
      ],
      [
        require("@/assets/img/img_careToon05-8.jpeg"),
        "무엇보다 작은 변화들이 보일 때마다 그 변화를 기록했다",
      ],
      [
        require("@/assets/img/img_careToon05-9.jpeg"),
        "조금씩 나아지는 몸을 보니 긍정적인 마음이 생기고",
      ],
      [
        require("@/assets/img/img_careToon05-10.jpeg"),
        "더 나아질것이라는 희망을 갖게 되었다",
      ],
    ],
  },
  {
    episode: 6,
    title: "치료 과정 이야기",
    thumbnail: require("@/assets/img/img_careToon06-1.jpeg"),
    content_img: [
      [
        require("@/assets/img/img_careToon06-1.jpeg"),
        "총 1년 3개월 간의 재활을 받게되었다",
      ],
      [
        require("@/assets/img/img_careToon06-2.jpeg"),
        "처음에는 땅을 딛는 것도 되지 않았다",
      ],
      [
        require("@/assets/img/img_careToon06-3.jpeg"),
        "소변줄을 차고 기저귀를 해야했는데, 다 커서 기저귀를 해야 한다는 사실이 너무 창피하고 속상했다",
      ],
      [
        require("@/assets/img/img_careToon06-4.jpeg"),
        "게다가 움직임이 어려우니 자주 씻을 수 없어 불편했다",
      ],
      [
        require("@/assets/img/img_careToon06-5.jpeg"),
        "하지만 계속되는 치료들로 근력이 향상되고, 서는 것이 가능해졌다",
      ],
      [
        require("@/assets/img/img_careToon06-6.jpeg"),
        "서서 균형을 잡는 연습을 계속하고, 걸음을 걷는 연습도 하게 되었다",
      ],
      [
        require("@/assets/img/img_careToon06-7.jpeg"),
        "점점 스스로 할 수 있는 일이 늘어났다",
      ],
      [
        require("@/assets/img/img_careToon06-8.jpeg"),
        "골절되었던 부분들이 붙고, 항상 입고 있던 척추보조기도 벗게 되었다",
      ],
      [
        require("@/assets/img/img_careToon06-9.jpeg"),
        "워커로 걷는 것이 편해지자, 지팡이로도 걷는 시도를 하게 되었다",
      ],
      [
        require("@/assets/img/img_careToon06-10.jpeg"),
        "속도는 느리지만, 하나씩 좋아지는 것들이 보이면서 더 열심히 재활을 받게 되었다",
      ],
    ],
  },
  {
    episode: 7,
    title: "간병인의 도움이 필요한 이유",
    thumbnail: require("@/assets/img/img_careToon07-1.jpeg"),
    content_img: [
      [
        require("@/assets/img/img_careToon07-1.jpeg"),
        "긴 재활기간 내내 보호자가 아닌 간병인분들께 도움을 받았다",
      ],
      [
        require("@/assets/img/img_careToon07-2.jpeg"),
        "제 간병에만 전념하기는 힘든 가족들의 사정이 있었다",
      ],
      [
        require("@/assets/img/img_careToon07-3.jpeg"),
        "언제 끝이 날지 모르는 긴 입원 기간 동안 병원비를 계속 부담해야 했기 때문에 부모님은 일을 계속 하셨다",
      ],
      [
        require("@/assets/img/img_careToon07-4.jpeg"),
        "그래서 간병인을 알아보게 되었는데 알아보는 과정이 순탄치 않았다",
      ],
      [
        require("@/assets/img/img_careToon07-5.jpeg"),
        "어디서 간병인을 알아보는지도 몰랐고",
      ],
      [
        require("@/assets/img/img_careToon07-6.jpeg"),
        "힘들게 간병인을 구해도 서로의 스케줄을 맞추는 것이 어려웠다",
      ],
      [
        require("@/assets/img/img_careToon07-7.jpeg"),
        "무엇보다 저의 몸 상태를 케어해주실 수 있는 분인지 알 수 없었다",
      ],
      [
        require("@/assets/img/img_careToon07-8.jpeg"),
        "서로간의 원하는 간병 비용을 맞추는 것도 정말 어려웠다",
      ],
      [
        require("@/assets/img/img_careToon07-9.jpeg"),
        "하지만 여러차례의 시행착오를 계속 겪으면서",
      ],
      [
        require("@/assets/img/img_careToon07-10.jpeg"),
        "저와 잘 맞는 간병인 여사님을 만났고 덕분에 점차 회복할 수 있었다",
      ],
    ],
  },
  {
    episode: 8,
    title: "친구 같은 간병인",
    thumbnail: require("@/assets/img/img_careToon08-1.jpeg"),
    content_img: [
      [
        require("@/assets/img/img_careToon08-1.jpeg"),
        "저를 돌봐주는 간병인들은 대부분 중국분이었다",
      ],
      [
        require("@/assets/img/img_careToon08-2.jpeg"),
        "하지만 이 사실을 몰랐을 때는 소통문제가 걱정되어 쉽게 간병인을 결정할 수 없었다",
      ],
      [
        require("@/assets/img/img_careToon08-3.jpeg"),
        "그런데 여러 간병인을 만나보니 국적은 상관이 없었다",
      ],
      [
        require("@/assets/img/img_careToon08-4.jpeg"),
        "오히려 긴 입원기간 동안 함께 하면서 재미있고 신선한 경험도 많이 했다",
      ],
      [require("@/assets/img/img_careToon08-5.jpeg"), "중국어도 알려주시고"],
      [
        require("@/assets/img/img_careToon08-6.jpeg"),
        "직접 요리를 하셔서 나눠 주시기도 했다",
      ],
      [
        require("@/assets/img/img_careToon08-7.jpeg"),
        "친해지며 장난도 치고 다양한 얘기를 나눴다",
      ],
      [
        require("@/assets/img/img_careToon08-8.jpeg"),
        "반복되는 치료들에 지쳐갔지만 옆에서 계속 응원해주시고 용기를 북돋아주셨다",
      ],
      [
        require("@/assets/img/img_careToon08-9.jpeg"),
        "어려운 상황임에도 친절하게 도와주시는 간병인분들께 감사한 마음이 들었다",
      ],
      [
        require("@/assets/img/img_careToon08-10.jpeg"),
        "간병인분들이 없었다면 긴 재활 기간을 혼자 버티기는 어려웠을거다",
      ],
    ],
  },
  {
    episode: 9,
    title: "병원 생활 꿀팁",
    thumbnail: require("@/assets/img/img_careToon09-1.jpeg"),
    content_img: [
      [
        require("@/assets/img/img_careToon09-1.jpeg"),
        "병원생활을 하면서 여러가지 꿀팁이 생겼다",
      ],
      [
        require("@/assets/img/img_careToon09-2.jpeg"),
        "제일 중요한 것은 사람들과 자주 소통하는 것이다",
      ],
      [require("@/assets/img/img_careToon09-3.jpeg"), "병원의 환우들과 의료진"],
      [
        require("@/assets/img/img_careToon09-4.jpeg"),
        "그리고 치료사 선생님들과 소통하며",
      ],
      [
        require("@/assets/img/img_careToon09-5.jpeg"),
        "위로받기도 하고, 내 상태도 파악하고, 여러 정보도 얻을 수 있었다",
      ],
      [
        require("@/assets/img/img_careToon09-6.jpeg"),
        "또 하나의 꿀팁은 정해진 치료 외에도 혼자 자주 운동하는 것이다",
      ],
      [
        require("@/assets/img/img_careToon09-7.jpeg"),
        "재활은 결국은 스스로 해야하는 것이기 때문이다",
      ],
      [
        require("@/assets/img/img_careToon09-8.jpeg"),
        "꾸준히 같은 동작을 반복하는 것이 회복에 많은 도움이 되었다",
      ],
      [
        require("@/assets/img/img_careToon09-9.jpeg"),
        "그리고, 나아지는 몸 상태를 기록하거나 찍어두는 것이 많은 도움이 되었다",
      ],
      [
        require("@/assets/img/img_careToon09-10.jpeg"),
        "매일 똑같이 반복되는 병원생활에서 내 몸이 나아지고 있다는 것을 깨달으면 맘이 편안해졌다",
      ],
    ],
  },
  {
    episode: 10,
    title: "병원의 치료 이야기",
    thumbnail: require("@/assets/img/img_careToon10-1.jpeg"),
    content_img: [
      [
        require("@/assets/img/img_careToon10-1.jpeg"),
        "재활병원에서는 다양한 치료들을 받게 된다",
      ],
      [
        require("@/assets/img/img_careToon10-2.jpeg"),
        "몸의 운동능력과 근력회복을 도와주는 운동치료와",
      ],
      [
        require("@/assets/img/img_careToon10-3.jpeg"),
        "일상생활의 동작들이 가능하도록 도와주는 작업치료",
      ],
      [
        require("@/assets/img/img_careToon10-4.jpeg"),
        "신경을 자극해주는 전기치료",
      ],
      [
        require("@/assets/img/img_careToon10-5.jpeg"),
        "통증을 줄여주는 통증치료가 있다",
      ],
      [
        require("@/assets/img/img_careToon10-6.jpeg"),
        "이런 다양한 치료들을 하루종일 받으며",
      ],
      [
        require("@/assets/img/img_careToon10-7.jpeg"),
        "새로운 운동기구들도 시도하게 된다",
      ],
      [
        require("@/assets/img/img_careToon10-8.jpeg"),
        "나 같은 경우, 서지 못하는 사람들이 설 수 있도록 도와주는 경사침대와 기립기를 하게 되었다",
      ],
      [
        require("@/assets/img/img_careToon10-9.jpeg"),
        "바로 엄청난 효과가 나타나는 건 아니지만",
      ],
      [
        require("@/assets/img/img_careToon10-10.jpeg"),
        "새롭고 신기한 치료들이 반복되면서 점차 몸이 나아지는 것을 느낄 수 있었다",
      ],
    ],
  },
  {
    episode: 11,
    title: "같은 방 할머니",
    thumbnail: require("@/assets/img/img_careToon11-1.jpeg"),
    content_img: [
      [
        require("@/assets/img/img_careToon11-1.jpeg"),
        "같은 방 옆자리에 할머니가 계셨다",
      ],
      [
        require("@/assets/img/img_careToon11-2.jpeg"),
        "할머니는 치매와 편마비 증상 때문에 입원하셨다",
      ],
      [
        require("@/assets/img/img_careToon11-3.jpeg"),
        "할머니는 병원의 인기스타였는데",
      ],
      [
        require("@/assets/img/img_careToon11-4.jpeg"),
        "말씀도 재미있게 잘하시고",
      ],
      [
        require("@/assets/img/img_careToon11-5.jpeg"),
        "누구에게나 먼저 다가가서 '안녕하세요'하고 인사를 해주셨다",
      ],
      [
        require("@/assets/img/img_careToon11-6.jpeg"),
        "대부분 눈을 감고 계셨지만, 질문을 하면 항상 대답도 잘해주셨다",
      ],
      [
        require("@/assets/img/img_careToon11-7.jpeg"),
        "같은 방 환우로써 끊임없이 말씀하실 때는 가끔 힘들 때도 있었지만",
      ],
      [
        require("@/assets/img/img_careToon11-8.jpeg"),
        "할머니가 계신 자리에는 항상 생기가 돌았다",
      ],
      [
        require("@/assets/img/img_careToon11-9.jpeg"),
        "그런 할머니도 양로원으로 가시는 날이 왔다",
      ],
      [
        require("@/assets/img/img_careToon11-10.jpeg"),
        "할머니의 빈자리가 느껴져 쓸쓸했지만 항상 건강하시면 좋겠다고 생각했다",
      ],
    ],
  },
  {
    episode: 12,
    title: "친구 상현이",
    thumbnail: require("@/assets/img/img_careToon12-1.jpeg"),
    content_img: [
      [
        require("@/assets/img/img_careToon12-1.jpeg"),
        "병원에 있을 때 가장 친하게 지낸 친구가 있다",
      ],
      [
        require("@/assets/img/img_careToon12-2.jpeg"),
        "경추 손상으로 인한 사지마비로 재활을 받기 위해 입원한 상현이라는 친구다",
      ],
      [
        require("@/assets/img/img_careToon12-3.jpg"),
        "상현이는 아버지가 간병을 하셨는데 항상 웃으며 장난을 치면서도 서로를 걱정하는 것이 느껴졌다",
      ],
      [
        require("@/assets/img/img_careToon12-4.jpeg"),
        "상현이는 갑작스러운 마비에 불편하고 힘들 텐데도 항상 밝고 장난기가 많은 친구였다",
      ],
      [
        require("@/assets/img/img_careToon12-5.jpeg"),
        "치료 사이마다 상현이 방에 가서 수다도 떨고, 간식도 나눠 먹으며 함께 즐거운 시간을 보내곤 했다",
      ],
      [
        require("@/assets/img/img_careToon12-6.jpeg"),
        "매일 반복되는 재활에 지쳤지만 같이 전동 자전거를 타며 고민을 나누고, 앞으로 더 잘 될 거라고 응원을 하기도 했다",
      ],
      [
        require("@/assets/img/img_careToon12-7.jpeg"),
        "힘들지만 힘들다는 말도 하지 못하는 애틋한 부자의 모습에",
      ],
      [
        require("@/assets/img/img_careToon12-8.jpeg"),
        "가족의 사랑과 배려가 느껴져서 괜스레 뭉클한 순간들이 많았다",
      ],
      [
        require("@/assets/img/img_careToon12-9.jpeg"),
        "삼촌은 저에게도 끊임없이 용기를 북돋아주시며 병원 생활의 큰 힘이 되어주셨다",
      ],
      [
        require("@/assets/img/img_careToon12-10.jpeg"),
        "상현이와 삼촌 덕분에 지루하고 힘들었던 병원 생활을 잘 버텨낼 수 있었다",
      ],
    ],
  },
  {
    episode: 13,
    title: "같은 방 이모",
    thumbnail: require("@/assets/img/img_careToon13-1.jpeg"),
    content_img: [
      [
        require("@/assets/img/img_careToon13-1.jpeg"),
        "종종 같은 방에서 계셨단 이모가 생각난다",
      ],
      [
        require("@/assets/img/img_careToon13-2.jpeg"),
        "치료를 받으시기보다는 일과에 맞추기 위해 치료를 받는 경우가 많았는데",
      ],
      [
        require("@/assets/img/img_careToon13-3.jpeg"),
        "이 이모는 달랐다. 이모는 회복에 대한 의지가 정말 강하셨다",
      ],
      [
        require("@/assets/img/img_careToon13-4.jpeg"),
        "때로는 치료에 대한 의견이 달라서 치료사 선생님들과도 자주 다투시기도 했지만",
      ],
      [
        require("@/assets/img/img_careToon13-5.jpeg"),
        "누구보다 운동의 중요성을 아시는 분이었고, 매일 치료가 끝난 후에도 개인운동을 하셨다",
      ],
      [
        require("@/assets/img/img_careToon13-6.jpeg"),
        "치료가 끝나고 이모와 함께 치료실에 가서 운동하고 도란도란 이야기하던 것이 기억난다",
      ],
      [
        require("@/assets/img/img_careToon13-7.jpeg"),
        "이모는 새로운 것에 도전하는 것도 겁내지 않으셨다",
      ],
      [
        require("@/assets/img/img_careToon13-8.jpeg"),
        "그리고 끝내 도전에 성공하는 모습을 보여주셔서 존경스러웠다",
      ],
      [
        require("@/assets/img/img_careToon13-9.jpeg"),
        "편마비로 한쪽 손의 기능이 부족하셨지만, 그 기능을 회복하기 위해서 끊임없이 노력하시는 모습이 정말 귀감이 되었다",
      ],
      [
        require("@/assets/img/img_careToon13-10.jpeg"),
        "지금은 이모와 저 모두 퇴원을 하게 되었고, 아직도 연락을 주고 받고 있다. 이모가 앞으로 더 건강한 생활을 하시길 응원한다",
      ],
    ],
  },
  {
    episode: 14,
    title: "삶의 종착지를 생각하며",
    thumbnail: require("@/assets/img/img_careToon14-1.jpeg"),
    content_img: [
      [
        require("@/assets/img/img_careToon14-1.jpeg"),
        "Momento Mori 라는 말을 들어보셨나요?",
      ],
      [
        require("@/assets/img/img_careToon14-2.jpeg"),
        "'그대는 죽어야 할 운명임을 기억하라'라는 뜻의 로마인의 가치관이다",
      ],
      [
        require("@/assets/img/img_careToon14-3.jpeg"),
        "우리는 살아가면서 더 많은 것을 얻으려 하고, 가진 것은 놓지 않으려고 애쓴다",
      ],
      [
        require("@/assets/img/img_careToon14-4.jpeg"),
        "하지만 모든 것에는 죽음이 따른다",
      ],
      [
        require("@/assets/img/img_careToon14-5.jpeg"),
        "사고로 병원생활을 오래하면서, 다양한 병으로 고생하시는 분들을 많이 보았다",
      ],
      [
        require("@/assets/img/img_careToon14-6.jpeg"),
        "언제 맞이할 줄 모르기 때문에 죽음은 더 무섭지만",
      ],
      [
        require("@/assets/img/img_careToon14-7.jpeg"),
        "결국 죽음 앞에서 모든 사람은 평등하고 겸손할 수 밖에 없다는 것을 깨달았다",
      ],
      [
        require("@/assets/img/img_careToon14-8.jpeg"),
        "결국 언젠가 맞이할 죽음이라면",
      ],
      [
        require("@/assets/img/img_careToon14-9.jpeg"),
        "살아있는 동안 가능한 후회하지 않도록",
      ],
      [
        require("@/assets/img/img_careToon14-10.jpeg"),
        "살아있는 동안 할 수 있는 것을 해야겠다",
      ],
    ],
  },
  {
    episode: 15,
    title: "케어네이툰 연재를 마치며",
    thumbnail: require("@/assets/img/img_careToon15-1.jpeg"),
    content_img: [
      [
        require("@/assets/img/img_careToon15-1.jpeg"),
        "오랜 시간에 걸친 케어네이툰 연재를 마치게 되었다",
      ],
      [
        require("@/assets/img/img_careToon15-2.jpeg"),
        "병원생활 이야기를 시간에 따라 케어네이툰으로 풀어내면서",
      ],
      [
        require("@/assets/img/img_careToon15-3.jpeg"),
        "초반에 잘 움직이지 못하던 때부터 점차 회복해 나아가는 이야기, 병원생활과 간병인 이야기까지 다시한번 그때의 기억이 떠올라 감사함을 느꼈다",
      ],
      [
        require("@/assets/img/img_careToon15-4.jpeg"),
        "무엇보다 담당자님과 함께 매 회에 대한 피드백을 주고 받으면서",
      ],
      [
        require("@/assets/img/img_careToon15-5.jpeg"),
        "아직은 많이 부족하지만 조금씩 더 좋은 방향으로 만화를 이끌어 갈 수 있었던 것 같다",
      ],
      [
        require("@/assets/img/img_careToon15-6.jpeg"),
        "무엇보다 더 좋은 간병문화를 만드는 케어네이션을 알리게 되어 좋았다",
      ],
      [
        require("@/assets/img/img_careToon15-7.jpeg"),
        "많은 환자와 보호자분들이 힘든 상황일 때 선택하게 되는 간병 앱인만큼",
      ],
      [
        require("@/assets/img/img_careToon15-8.jpeg"),
        "케어네이션을 통해서 만나게 되는 인연이 좋으시면 좋겠다",
      ],
      [
        require("@/assets/img/img_careToon15-9.jpeg"),
        "병원생활중이신 많은 분들의 빠른 회복을 기원하며",
      ],
      [
        require("@/assets/img/img_careToon15-10.jpeg"),
        "그동안 연두X케어네이션 만화 케어네이툰을 지켜봐주셔서 감사합니다",
      ],
    ],
  },
];
