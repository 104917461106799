var render = function render(){var _vm=this,_c=_vm._self._c;return _c('main',[_vm._m(0),_c('article',{staticClass:"commuAco",class:_vm.subNavStat ? 'on' : ''},[_c('div',{staticClass:"basicWrap clear"},[_vm._m(1),_c('div',{staticClass:"right"},[_c('button',{attrs:{"type":"button"},on:{"click":function($event){_vm.subNavStat = !_vm.subNavStat}}},[_vm._v(_vm._s(_vm.categoryTag))]),_c('ul',[_c('li',{class:this.categoryTag == '언론소식' ? 'active' : '',on:{"click":function($event){return _vm.cateChange('언론소식')}}},[_vm._v(" 언론 소식 ")]),_c('li',{class:this.categoryTag == '공지' ? 'active' : '',on:{"click":function($event){return _vm.cateChange('공지')}}},[_vm._v(" 공지 ")]),_c('li',{class:this.categoryTag == '캠페인' ? 'active' : '',on:{"click":function($event){return _vm.cateChange('캠페인')}}},[_vm._v(" 캠페인 ")]),_c('li',{class:this.categoryTag == '인터뷰' ? 'active' : '',on:{"click":function($event){return _vm.cateChange('인터뷰')}}},[_vm._v(" 인터뷰 ")])])])])]),_c('section',{staticClass:"commu"},[_c('div',{staticClass:"basicWrap"},[_c('h3',[_vm._v(_vm._s(_vm.categoryTag))]),_c('ul',{staticClass:"commu__list clear"},_vm._l((_vm.listData.data.list),function(listItem,itemIndex){return _c('li',{key:itemIndex,on:{"click":function($event){_vm.indexSave(itemIndex), _vm.enterDetail(listItem.id, _vm.categoryTag)}}},[_c('a',{class:listItem.type == 'youtube' ? 'video' : 'post',attrs:{"href":""}},[_c('figure',{style:({
                backgroundImage:
                  'url(https://s3.ap-northeast-2.amazonaws.com/img.carenation.kr/' +
                  listItem.thumbnail +
                  ')',
              })}),_c('div',{staticClass:"commu__list--tit"},[_c('p',[_vm._v(_vm._s(listItem.title))])])])])}),0),_c('div',{staticClass:"commu__btn clear"},[(this.curPage > this.pageSize)?_c('button',{staticClass:"prev",attrs:{"type":"button"},on:{"click":function($event){return _vm.pagePrev()}}},[_vm._v(" 이전 ")]):_vm._e(),_vm._l((_vm.pageNum),function(pageItem){return _c('button',{key:pageItem,class:_vm.$route.query.page == pageItem ? 'active' : '',attrs:{"type":"button"},on:{"click":function($event){return _vm.pageChange(pageItem)}}},[_vm._v(" "+_vm._s(pageItem)+" ")])}),(this.realendPage < this.totalPage)?_c('button',{staticClass:"next",attrs:{"type":"button"},on:{"click":function($event){return _vm.pageNext()}}},[_vm._v(" 다음 ")]):_vm._e()],2)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('article',{staticClass:"subBan ban04"},[_c('div',{staticClass:"subBan__wrap"},[_c('h2',[_vm._v("케어네이션 뉴스")]),_c('p',[_vm._v("보다 좋은 간병 문화를 만드는 케어네이션이 궁금하신가요?")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"left"},[_c('span',[_vm._v("케어네이션 뉴스")])])
}]

export { render, staticRenderFns }