import Vue from "vue";
import VueRouter from "vue-router";
import homeMain from "@/components/commuMain/commuMain.vue";
import CommonTerms from "@/components/commonLayout/commonTerms.vue";
import commuDictionary from "@/components/commuDictionary/commuDictionary.vue";
import commuDictionaryDet from "@/components/commuDictionary/commuDictionaryDet.vue";
import commuStory from "@/components/commuStory/commuStory.vue";
import commuStoryDet from "@/components/commuStory/commuStoryDet.vue";
import commuXfile from "@/components/commuXfile/commuXfile.vue";
import commuXfileDet from "@/components/commuXfile/commuXfileDet.vue";
import commuNews from "@/components/commuNews/commuNews.vue";
import commuNewsDet from "@/components/commuNews/commuNewsDet.vue";
import commuInfo from "@/components/commuInfo/commuInfo.vue";
import commuInfoDet from "@/components/commuInfo/commuInfoDet.vue";
import commuToon from "@/components/commuToon/commuToon.vue";
import commuToonDet from "@/components/commuToon/commuToonDet.vue";
import notFound from "@/components/commonLayout/notFound.vue";

Vue.use(VueRouter);

const route = [
  // 메인 페이지
  { path: "/", component: homeMain },

  // 약관
  { path: "/terms", name: "protecter", component: CommonTerms },
  { path: "/terms", name: "protecterPrivacy", component: CommonTerms },
  { path: "/terms", name: "caregiver", component: CommonTerms },
  { path: "/terms", name: "caregiverPrivacy", component: CommonTerms },
  { path: "/terms", name: "location", component: CommonTerms },

  // 간병백과
  { path: "/dictionary", name: "dictionary", component: commuDictionary },
  {
    path: "/dictionary/detail",
    name: "dictionaryDet",
    component: commuDictionaryDet,
  },
  // 간병이야기
  { path: "/story", name: "commuStory", component: commuStory },
  { path: "/story/detail", name: "commuStoryDet", component: commuStoryDet },
  // x파일
  { path: "/xFile", name: "commuXfile", component: commuXfile },
  { path: "/xFile/detail", name: "commuXfileDet", component: commuXfileDet },
  // 뉴스
  { path: "/news", name: "commuNews", component: commuNews },
  { path: "/news/detail", name: "commuNewsDet", component: commuNewsDet },
  // 건강정보
  { path: "/info", name: "commuInfo", component: commuInfo },
  { path: "/info/detail", name: "commuInfoDet", component: commuInfoDet },
  // 케어네이툰
  { path: "/toon", name: "commuToon", component: commuToon },
  { path: "/toon/detail", name: "commuToonDet", component: commuToonDet },

  // 에러페이지
  { path: "*", name: "notFound", component: notFound },
];

// Vue 라우터 인스턴스 생성
const router = new VueRouter({
  mode: "history",
  routes: route,
  // 라우터 이동 시 페이지 최상단
  scrollBehavior: function (to, _from, savedPosition) {
    var newsPagination = sessionStorage.getItem("changed");
    if (savedPosition) {
      return savedPosition;
    } else if (JSON.parse(newsPagination) == true) {
      return;
    } else {
      window.scrollTo(0, 0);
    }
  },
});

export default router;
