<template>
  <main>
    <section class="main">
      <article class="main__video">
        <div class="basicWrap">
          <div class="main__video--tit">
            <h2>
              진료부터 간병, 요양까지<br />
              <p>모든 <b>헬스케어</b>를 <b>연결</b>하다</p>
            </h2>
            <div class="storeBtnWrap">
              <button type="button" id="main_goo" class="google" @click="goToGoogleStore()">
                구글 플레이 스토어
              </button>
              <button type="button" id="main_app" class="apple" @click="goToAppleStore()">
                앱 스토어
              </button>
              <button type="button" class="mobileDown">앱 다운로드</button>
            </div>
          </div>
        </div>
        <video autoplay muted loop width="100%"
        src="@/assets/video/top_video.mp4"
        type=“video/mp4” />
      </article>
      <slick :options="slickOptions01" class="main__ban">
        <div class="ban01">
          <p>4년 연속 <span>대한민국소비자만족도</span> 1위 수상</p>
        </div>
        <div class="ban02">
          <p>3년 연속 <span>대한민국 최고브랜드대상</span> 수상</p>
        </div>
        <div class="ban03">
          <p>3년 연속 <span>앱어워드코리아 올해의 앱</span> 대상 수상</p>
        </div>
        <div class="ban04">
          <p>2년 연속 <span>대한민국 산업대상</span> 수상</p>
        </div>
        <div class="ban05">
          <small>케어네이션 X 삼성화재</small>
          <p>
            국내 최초 모바일 전용<br />
            간병인배상책임보험
          </p>
        </div>
      </slick>
      <article class="main__txtBg">
        <h2>
          4년 연속 소비자만족도 1위<br />
          돌봄 플랫폼<br />
          <span>
            내 가족이니까 믿고 맡길 수 있게<br />
            케어네이션이 그 여정을 함께 합니다
          </span>
        </h2>
      </article>
      <article class="main__careCalc clear">
        <div class="main__careCalc--tit">
          <h2>
            한눈에 알아보는<br />
            예상 간병비
          </h2>
          <p>
            진단명과 환자 상태를 입력해 간편하게<br />
            예상 간병비를 계산할 수 있습니다.
          </p>
          <button type="button" onclick="window.open('https://carenation.kr/calculate/predict')">
            예상 간병비 계산하러 가기
          </button>
        </div>
        <div class="main__careCalc--img">
          <div class="topBox" data-aos="fade-up" data-aos-duration="3000" v-if="show == true">
            <p>진단명</p>
            <div class="fadeBox">
              <span>{{ diseaseList[countNum].name }}</span>
            </div>
          </div>
          <div class="bottomBox" data-aos="fade-up" data-aos-duration="3000" v-if="show == true">
            <div class="bottomBox__tit">
              <p>하루 예상 간병비</p>
              <div class="fadeBox">
                <span
                  ><VueCountUp :endVal="diseaseList[countNum].average" :duration="500" />원</span
                >
              </div>
              <small data-aos="fade-in" data-aos-duration="3000">( 내국인 식대 포함 기준 )</small>
            </div>
            <PreCalcChart :diseaseList="diseaseList" :countNum="countNum" />
          </div>
          <span class="caution" data-aos="fade-in" data-aos-duration="3000">* 2022.12 기준</span>
        </div>
      </article>
      <article class="main__tabSlide">
        <div class="basicWrap">
          <h2 class="a11y-hidden">주요 서비스</h2>
          <div class="tabBox"></div>
          <div class="scrolling">
            <span></span>
            <span></span>
            <span></span>
          </div>
          <slick :options="slickOptions02" ref="slick" class="slideWrap clear">
            <div class="slideWrap__item slide01">
              <div class="slideWrap__item--tit">
                <strong>간병 서비스</strong>
                <p>
                  간병인 프로필/후기/간병비 확인 후,<br />
                  카드로 간편 결제하세요
                </p>
                <small
                  >4년 연속 소비자만족도 1위 간병인 찾기<br />
                  오늘도 더 좋은 간병 문화를 만들어갑니다</small
                >
              </div>
              <div class="slideWrap__item--img">
                <figure></figure>
              </div>
            </div>
            <div class="slideWrap__item slide02">
              <div class="slideWrap__item--tit">
                <strong>동행 서비스</strong>
                <p>
                  집부터 병원까지,<br />
                  든든한 동행인을 찾아보세요
                </p>
                <small
                  >부모님 동행, 마음까지 함께 해 줄 사람<br />
                  케어네이션에 있습니다</small
                >
              </div>
              <div class="slideWrap__item--img">
                <figure></figure>
              </div>
            </div>
            <div class="slideWrap__item slide03">
              <div class="slideWrap__item--tit">
                <strong>가사돌봄 서비스</strong>
                <p>
                  가사부터 돌봄까지<br />
                  필요한 만큼 편리하게 이용해 보세요
                </p>
                <small
                  >맞춤형 가사돌봄을 통해<br />
                  케어메이트가 따뜻한 일상을 함께 합니다</small
                >
              </div>
              <div class="slideWrap__item--img">
                <figure></figure>
              </div>
            </div>
            <div class="slideWrap__item slide04">
              <div class="slideWrap__item--tit">
                <strong>방문요양 서비스</strong>
                <p>
                  방문요양, 주야간보호, 방문간호, 방문목욕,<br />
                  단기보호, 복지용구 시설들을 편하게 찾아보세요
                </p>
                <small
                  >기관평가와 함께<br />
                  집근처 센터까지 확인할 수 있어요</small
                >
              </div>
              <div class="slideWrap__item--img">
                <figure></figure>
              </div>
            </div>
            <div class="slideWrap__item slide05">
              <div class="slideWrap__item--tit">
                <strong>간병비·병원비 비대면 결제</strong>
                <p>
                  병원에 갈 필요 없이 간병비 따로 병원비 따로!<br />
                  이제 앱으로 편하게 결제하세요
                </p>
                <small>*병원비 결제 서비스는 추후 업데이트 될 예정입니다</small>
              </div>
              <div class="slideWrap__item--img">
                <figure></figure>
              </div>
            </div>
            <div class="slideWrap__item slide06">
              <div class="slideWrap__item--tit">
                <strong>의료용품 스토어</strong>
                <p>
                  매번 구매해야 하는 의료용품,<br />
                  합리적인 가격에 간편하게 구매하세요
                </p>
                <small
                  >원하는 제품을 손쉽게<br />
                  검색할 수 있습니다</small
                >
              </div>
              <div class="slideWrap__item--img">
                <figure></figure>
              </div>
            </div>
            <div class="slideWrap__item slide07">
              <div class="slideWrap__item--tit">
                <strong>병원·약국 찾기</strong>
                <p>
                  근처 병원·약국의<br />
                  영업시간, 진료과목을 확인하세요
                </p>
                <small
                  >케어네이션 맵으로 병원 찾기가<br />
                  더욱 편리해졌습니다</small
                >
              </div>
              <div class="slideWrap__item--img">
                <figure></figure>
              </div>
            </div>
            <div class="slideWrap__item slide08">
              <div class="slideWrap__item--tit">
                <strong>복약 정보 검색·알림 관리</strong>
                <p>
                  잊기 쉬운 약 복용 방법<br />
                  대신 챙겨드릴게요
                </p>
                <small
                  >처방받은 약, 매일 챙겨 먹어야 하는 영양제<br />
                  모두 깜빡하지 않도록 복약 알림을 설정하세요</small
                >
              </div>
              <div class="slideWrap__item--img">
                <figure></figure>
              </div>
            </div>
            <div class="slideWrap__item slide09">
              <div class="slideWrap__item--tit">
                <strong>요양원 찾기</strong>
                <p>
                  우리 부모님 더욱 편안하게 모실 수 있도록<br />
                  원하는 등급의 시설을 찾아보세요
                </p>
                <small
                  >요양원, 노인공동생활 가정 등을<br />
                  빠르게 검색할 수 있어요</small
                >
              </div>
              <div class="slideWrap__item--img">
                <figure></figure>
              </div>
            </div>
            <div class="slideWrap__item slide10">
              <div class="slideWrap__item--tit">
                <strong>부고장 관리·상조·장례</strong>
                <p>
                  장례 시설 찾기, 부고장 작성 및 관리,<br />
                  상조가입 등이 가능해요
                </p>
                <small
                  >갑작스럽게 준비해야 하는 장례 여정을<br />
                  케어네이션이 함께 합니다</small
                >
              </div>
              <div class="slideWrap__item--img">
                <figure></figure>
              </div>
            </div>
          </slick>
          <div class="main__tabSlide--pcBtn">
            <button type="button" class="prev">이전</button>
            <button type="button" class="next">다음</button>
          </div>
        </div>
      </article>
      <article class="main__security">
        <div class="main__secirity--layout clear">
          <figure></figure>
          <div class="main__security--tit">
            <h2>
              안심할 수 있는<br />
              개인정보 보호와 정보 보안
            </h2>
            <p>
              앱 내의 모든 정보는 고도화된 암호화 알고리즘으로<br />
              철저하게 보호하고 있어 개인정보 유출 우려가 없습니다
            </p>
          </div>
        </div>
      </article>
      <article class="main__cycle">
        <div class="main__cycle--tit">
          <h2>
            라이프 사이클에 맞는<br />
            모든 헬스케어 서비스를 상황별로 제공합니다
          </h2>
          <p>
            케어네이션은 빅데이터를 활용하여 의료산업 서비스의 혁신을 이끌고자 합니다<br />
            가벼운 경증 환자부터 만성질환 환자 모두가 편하게 이용할 수 있는<br />
            최고의 의료 서비스를 제공하기 위해 노력하겠습니다
          </p>
        </div>
        <figure></figure>
      </article>
      <article class="main__slogan">
        <div class="main__slogan--wrap clear">
          <h2>
            모든 헬스케어를 연결하다<br />
            케어네이션
          </h2>
          <img src="@/assets/img/icon_logo_wh.png" alt="" aria-hidden />
        </div>
      </article>
    </section>
  </main>
</template>

<script>
import diseaseList from "@/assets/js/preCalc";
import serviceList from "@/assets/js/serviceCont.js";
import PreCalcChart from "./commuPreCalc.vue";
import Slick from "vue-slick";
import "slick-carousel/slick/slick.css";
import VueCountUp from "vue-countup-v2";

export default {
  components: {
    Slick: Slick,
    VueCountUp: VueCountUp,
    PreCalcChart: PreCalcChart, //예상 간병비 차트
  },
  data() {
    return {
      show: true,
      diseaseList: diseaseList, // 예상 간병비 차트에 들어갈 질병 정보
      serviceList: serviceList, // 메인슬라이드에 들어갈 서비스 정보
      countNum: 0, // 예상 간병비 카운트
      googleStore:
        "https://play.google.com/store/apps/details?id=kr.carenation.protector",
      appleStore:
        "https://apps.apple.com/kr/app/id1516171776",
      slickOptions01: {
        arrows: false,
        draggable: false,
        swipe: false,
        autoplay: true,
        autoplaySpeed: 3000,
      },
      slickOptions02: {
        slidesToShow: 1,
        prevArrow: ".main__tabSlide--pcBtn .prev",
        nextArrow: ".main__tabSlide--pcBtn .next",
        dots: true,
        dotsClass: "main__tabSlide--btnWrap clear",
        appendDots: ".tabBox",
        customPaging: function (slide, index) {
          return '<h3><button type="button">' + serviceList[index].title + "</button></h3>";
          // return '<h3 class="' + (slide === index ? 'active' : '') + '">' + '<button type="button">' + serviceList[index].title + '</button>' + '</h3>';
        },
        responsive: [
          {
            breakpoint: 1023,
            settings: {
              dotsClass: "main__tabSlide--mobileBtn clear",
              appendDots: ".main__tabSlide .basicWrap",
              customPaging: function (slide, index) {
                return '<button type="button">' + index + "</button>";
              },
            },
          },
        ],
      },
    };
  },
  methods: {
    goToGoogleStore() {
      window.open(this.googleStore);
    },
    goToAppleStore() {
      window.open(this.appleStore);
    },
  },
  mounted() {
    setInterval(() => {
      this.countNum = (this.countNum + 1) % 4;
      this.show = false;
      setTimeout(() => {
        this.show = true;
      }, 100);
    }, 4000);
  },
};
</script>

<style>
.main__tabSlide--btnWrap .slick-active h3 button {
  color: #111;
  font-weight: bold;
}
.main__tabSlide--mobileBtn .slick-active button {
  background-image: url(@/assets/img/btn_pager_ac.png);
}
</style>
