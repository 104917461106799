/**
 * OS Type Check : Tablet / Mobile / Web
 * ——————————————————————————————————————————————————————————
 */
export function osType() {
  let os = "";
  if (navigator.userAgent.match(/Tablet|iPad/i)) {
    os = "tablet";
  } else if (
    navigator.userAgent.match(
      /Mobile|Windows Phone|Lumia|Android|webOS|iPhone|iPod|Blackberry|PlayBook|BB10|Opera Mini|\bCrMo\/|Opera Mobi/i,
    )
  ) {
    os = "mobile";
  } else {
    os = "web";
  }

  return os;
}

// 스토어 타입
export function storeType() {
  var userOs = navigator.userAgent.replace(/ /g, "").toLowerCase();
  let os = "";
  if (userOs.match(/macintosh/i) == "macintosh") {
    os = "mac";
  } else if (userOs.match(/window/i) == "window") {
    os = "window";
  } else if (userOs.match(/android/i) == "android") {
    os = "android";
  } else if (userOs.match(/iphone/i) == "iphone") {
    os = "ios";
  } else if (userOs.match(/ipad/i) == "ipad") {
    os = "ipad";
  }
  return os;
}
