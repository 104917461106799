<template>
  <main>
    <article class="subBan ban02">
      <div class="subBan__wrap">
        <h2>간병이야기</h2>
        <p>간병이 어색한 초보 보호자/간병인을 위한 케어네이션 간병 가이드</p>
      </div>
    </article>
    <section class="commuDet">
      <div class="basicWrap">
        <div class="commuDet__tit clear">
          <span>현재글</span>
          <div>
            <h3>{{ curData.title }}</h3>
          </div>
        </div>
        <div class="commuDet__con">
          <div class="commuDet__con--video" v-if="curData.youtube">
            <iframe
              width="100%"
              height="100%"
              :src="curData.youtube.replace('watch?v=', 'embed/').replace('&feature=youtu.be', '')"
              allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </div>
          <p v-html="curData.content" v-if="curData.content"></p>
        </div>
        <div class="commuDet__list">
          <div
            class="commuDet__list--next clear"
            :class="nextData == null ? 'disabled' : ''"
            @click="itemNext()"
          >
            <span>다음글</span>
            <h2 class="title" v-if="nextData == null">다음 글이 없습니다.</h2>
            <h2 class="title" v-if="nextData !== null">{{ nextData.title }}</h2>
          </div>
          <div
            class="commuDet__list--prev clear"
            :class="preData == null ? 'disabled' : ''"
            @click="itemPrev()"
          >
            <span>이전글</span>
            <h2 class="title" v-if="preData == null">이전 글이 없습니다.</h2>
            <h2 class="title" v-if="preData !== null">{{ preData.title }}</h2>
          </div>
          <div class="commuDet__list--btn clear">
            <a href="" @click="goToList()">목록</a>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import * as Utils from "../../constants/utils";
import Api from "../../api/api";
export default {
  data() {
    return {
      contId: null,
      curData: [],
      preData: [],
      nextData: [],
      defaultMgs: "",
      curPage: 1,
      curItem: 0, // 현재 글 index,
      categoryTag: this.$route.query.tag, // 전달받은 태그
      pageLimit: Utils.osType() === "mobile" ? 8 : 9,
    };
  },
  watch: {
    $route: function () {
      if (this.$route.query.id !== null) {
        // this.curItem = sessionStorage.getItem("itemIndex");
        this.contId = this.$route.query.contId;
        this.getContDetailApi();
      }
    },
  },
  mounted() {
    sessionStorage.setItem("changed", false);
    this.curItem = sessionStorage.getItem("itemIndex");
    this.contId = this.$route.query.contId;
    this.getContDetailApi();
  },
  methods: {
    // 콘텐츠 상세 불러오기
    getContDetailApi() {
      Api.getDetail("cgsnews", this.categoryTag, parseInt(this.contId))
        .then((response) => {
          if (response.status === 200) {
            let data = response.data;
            if (data.code === 200) {
              this.curData = data.data.info;
              this.preData = data.data.pre;
              this.nextData = data.data.next;
            } else {
              // let msg = Utils.isEmpty(data.message) ? this.defaultMsg : data.message;
              alert(this.defaultMgs);
            }
          } else {
            alert(this.defaultMsg);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 뒤로가기
    goToList() {
      this.$router.push({
        name: "commuStory",
        query: {
          tag: this.categoryTag,
          page: this.$route.query.page,
        },
      });
      window.scrollTo(0, 0);
    },
    // 다음, 이전 버튼
    itemNext() {
      if (0 < this.curItem) {
        if (this.curItem == sessionStorage.getItem("itemIndex")) {
          sessionStorage.setItem("itemIndex", parseInt(this.curItem) - 1);
          this.curItem = this.curItem - 1;
        }
        this.$router.push({
          name: "commuStoryDet",
          query: {
            contId: this.nextData.id,
            tag: this.categoryTag,
            page: this.$route.query.page,
          },
        });
      } else {
        sessionStorage.setItem("itemIndex", this.$route.query.limit);
        this.curItem = 9;
        this.$router.push({
          name: "commuStoryDet",
          query: {
            contId: this.nextData.id,
            tag: this.categoryTag,
            page: parseInt(this.$route.query.page - 1),
          },
        });
      }
    },
    itemPrev() {
      if (this.curItem < parseInt(this.pageLimit - 1)) {
        if (this.curItem == sessionStorage.getItem("itemIndex")) {
          sessionStorage.setItem("itemIndex", parseInt(this.curItem) + parseInt(1));
          this.curItem = this.curItem + 1;
        }
        this.$router.push({
          name: "commuStoryDet",
          query: {
            contId: this.preData.id,
            tag: this.categoryTag,
            page: this.$route.query.page,
          },
        });
      } else {
        sessionStorage.setItem("itemIndex", 0);
        this.curItem = 0;
        this.$router.push({
          name: "commuStoryDet",
          query: {
            contId: this.preData.id,
            tag: this.categoryTag,
            page: parseInt(this.$route.query.page) + parseInt(1),
          },
        });
      }
    },
  },
};
</script>
