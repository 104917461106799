const diseaseArr = [
  {
      name: "치매",
      min: 110000,
      average: 130000,
      max: 145000,
  },
  {
      name: "마비(편마비, 전신)",
      min: 110000,
      average: 128000,
      max: 150000,
  },
  {
      name: "폐암",
      min: 100000,
      average: 120000,
      max: 135000,
  },
  {
      name: "뇌출혈",
      min: 110000,
      average: 130000,
      max: 150000,
  },
];

export default diseaseArr;
