<template>
  <main>
    <article class="subBan ban05">
      <div class="subBan__wrap">
        <h2>건강 정보</h2>
        <p>건강에 대한 유익한 모든 정보</p>
      </div>
    </article>
    <article class="commuAco" :class="subNavStat ? 'on' : ''">
      <div class="basicWrap clear">
        <div class="left"><span>건강 정보</span></div>
        <div class="right">
          <button type="button" @click="subNavStat = !subNavStat">
            {{ categoryTag }}
          </button>
          <ul>
            <li
              @click="cateChange('케어 매거진')"
              :class="this.categoryTag == '케어 매거진' ? 'active' : ''"
            >
              케어 매거진
            </li>
            <li
              @click="cateChange('질환 정보')"
              :class="this.categoryTag == '질환 정보' ? 'active' : ''"
            >
              질환 정보
            </li>
          </ul>
        </div>
      </div>
    </article>
    <section class="commu">
      <div class="basicWrap">
        <h3>{{ categoryTag }}</h3>
        <ul class="commu__list clear">
          <li
            v-for="(listItem, itemIndex) in listData.data.list"
            :key="itemIndex"
            @click="indexSave(itemIndex), enterDetail(listItem.id, categoryTag)"
          >
            <a href="" :class="listItem.type == 'youtube' ? 'video' : 'post'">
              <figure
                :style="{
                  backgroundImage:
                    'url(https://s3.ap-northeast-2.amazonaws.com/img.carenation.kr/' +
                    listItem.thumbnail +
                    ')',
                }"
              ></figure>
              <div class="commu__list--tit">
                <p>{{ listItem.title }}</p>
              </div>
            </a>
          </li>
        </ul>
        <div class="commu__btn clear">
          <button
            type="button"
            class="prev"
            @click="pagePrev()"
            v-if="this.curPage > this.pageSize"
          >
            이전
          </button>
          <button
            type="button"
            v-for="pageItem in pageNum"
            :key="pageItem"
            @click="pageChange(pageItem)"
            :class="$route.query.page == pageItem ? 'active' : ''"
          >
            {{ pageItem }}
          </button>
          <button
            type="button"
            class="next"
            @click="pageNext()"
            v-if="this.realendPage < this.totalPage"
          >
            다음
          </button>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import * as Utils from "../../constants/utils";
import Api from "../../api/api";
export default {
  data() {
    return {
      categoryTag: "", // ## 카테고리 태그
      subNavStat: false, //## 서브 네비게이션 토글
      // 리스트
      listData: { data: { list: [] } }, //## 리스트 데이터
      totalCount: 0, // 리스트 전체 데이터 수

      // 페이지네이션
      pageSize: Utils.osType() === "mobile" ? 5 : 10,
      totalPage: 0, //## 전체 페이지 수
      curPage: 1, //## 현재 페이지
      pageTab: 0, //## 현재 페이지들 위치
      numBox: [], // 페이지네이션에 들어갈 숫자 넣어놓는 임시 박스
      pageNum: [], // 페이지네이션에 들어갈 숫자
      startPage: 1, // 시작 페이지
      endPage: 0, // 끝 페이지
      realendPage: 0, // 페이지네이션 계산용 끝 페이지
      limit: Utils.osType() === "mobile" ? 8 : 9, // pc일때 페이지당 9개, 모바일일 때 8개 표시
      curItem: 0, // 현재 아이템

      preFlag: 0, //## 이전 버튼 Boolean
      nextFlag: 0, //## 다음 버튼 Boolean

      defaultMsg: "일시적인 오류가 발생하였습니다. 잠시 후 다시 시도해주세요.",
    };
  },
  mounted() {
    if (this.$route.query.page !== undefined) {
      this.curPage = this.$route.query.page;
      this.categoryTag = this.$route.query.tag;
    } else {
      // console.log('noQuery')
    }
    this.getListApi();
  },
  updated() {
    this.$router
      .push({
        name: "commuInfo",
        query: {
          tag: this.categoryTag,
          page: this.curPage,
        },
      })
      .catch(() => {});
  },
  watch: {
    $route: function () {
      if (this.$route.query.page != null) {
        // 페이지 렌더링
        this.curPage = this.$route.query.page;
        this.categoryTag = this.$route.query.tag;
        this.getListApi();
      }
    },
  },
  methods: {
    getListApi() {
      const queryStr = `?page=${this.curPage}&limit=${this.limit}`;
      Api.getList("cgshealth", this.categoryTag, queryStr) // 카테고리, 태그, 현재 페이지, 페이지당 게시물 개수
        .then((response) => {
          if (response.status === 200) {
            let data = response.data;
            this.listData = data; // 리스트 가져오기
            this.totalCount = data.data.total; // 리스트 데이터 수 가져오기
            if (data.data.list !== null) {
              // 가져온 데이터가 null이 아닐 경우
              let pageTab = this.curPage / this.pageSize;
              if (pageTab.toString().includes(".")) {
                pageTab = Math.floor(pageTab);
              } else {
                pageTab = pageTab - 1;
              }
              this.pageTab = pageTab;
              this.renderContList();
            } else {
              console.log(this.defaultMsg);
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 리스트 생성
    renderContList() {
      if (this.totalCount !== null) {
        // 리스트 데이터 수가 null이 아닐 경우
        this.totalPage = Math.ceil(this.totalCount / this.limit); // 리스트 전체 데이터 수 나누기 한 페이지에 보일 페이지 (올림)
        this.renderPagination();
      }
    },
    // 페이지네이션
    renderPagination() {
      this.startPage = this.pageTab * this.pageSize + 1;
      this.endPage = this.pageTab * this.pageSize + this.pageSize;
      this.realendPage = this.endPage > this.totalPage ? this.totalPage : this.endPage;
      this.makePage();
    },
    makePage() {
      this.numBox = [];
      for (let i = this.startPage; i < this.realendPage; i++) {
        this.numBox.push(i);
      }
      this.numBox.push(this.realendPage);
      this.pageNum = this.numBox;
    },
    pageNext() {
      const nextPageFirst = this.realendPage + 1;
      this.$router.push({
        name: "commuInfo",
        query: {
          tag: this.categoryTag,
          page: nextPageFirst,
        },
      });
      this.startPage = this.startPage + this.pageSize;
      this.endPage = this.endPage + this.pageSize;
      if (this.realendPage + this.pageSize < this.totalPage) {
        this.realendPage = this.realendPage + 5;
      } else {
        this.realendPage = this.totalPage;
      }
      this.makePage();
    },
    pagePrev() {
      const prevPageFirst = this.startPage - 1;
      this.$router.push({
        name: "commuInfo",
        query: {
          tag: this.categoryTag,
          page: prevPageFirst,
        },
      });
      this.startPage = this.startPage - this.pageSize;
      this.endPage = this.endPage - this.pageSize;
      if (this.realendPage - this.pageSize > this.pageSize) {
        this.realendPage = this.realendPage - 5;
      } else {
        this.realendPage = this.pageSize;
      }
      this.makePage();
    },
    pageChange(picked) {
      if (picked !== this.$route.query.page) {
        this.$router.push({
          name: "commuInfo",
          query: {
            tag: this.categoryTag,
            page: picked,
          },
        });
        this.curPage = picked;
      } else {
        // console.log('same page');
      }
    },
    indexSave(itemIndex) {
      sessionStorage.setItem("itemIndex", itemIndex);
    },
    enterDetail(pickedId) {
      this.$router.push({
        name: "commuInfoDet",
        query: {
          contId: pickedId,
          tag: this.categoryTag,
          page: this.curPage,
        },
      });
    },
    // 2depth 변경
    cateChange(pickCategory) {
      this.curPage = 1;
      this.subNavStat = false;
      this.categoryTag = pickCategory;
      this.getListApi();
      this.makePage();
    },
  },
};
</script>

<style></style>
