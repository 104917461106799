<template>
  <canvas ref="chart"></canvas>
</template>

<script>
import Chart from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
export default {
  props: {
    countNum: Number,
    diseaseList: Array,
  },
  data() {
    return {
      myChart: null,
    };
  },
  mounted() {
    const canvas = this.$refs.chart;
    canvas.width = canvas.offsetWidth;
    canvas.height = canvas.offsetHeight;
    const isMiddle = function (context) {
      return context.dataIndex === 1;
    };
    const ctx = canvas.getContext("2d");
    const gradientFill = ctx.createLinearGradient(500, 0, 100, 0);
    gradientFill.addColorStop(0, "#edf5fd");
    gradientFill.addColorStop(1, "#d5edff");
    this.myChart = new Chart(ctx, {
      type: "line",
      data: {
        labels: ["최소금액", "평균 금액", "최대 금액"],
        datasets: [
          {
            data: [
              this.diseaseList[this.countNum].min,
              this.diseaseList[this.countNum].average,
              this.diseaseList[this.countNum].max,
            ],
            fill: true,
            borderWidth: 1,
            borderColor: "#1073ba",
            pointRadius: 8,
            pointBorderWidth: 1,
            pointBorderColor: "#1073ba",
            pointBackgroundColor: "#fff",
            pointHoverBorderColor: "#1073ba",
            pointHoverBackgroundColor: "#fff",
            pointHoverBorderWidth: 4,
            pointHoverRadius: 8,
            backgroundColor: gradientFill,
          },
        ],
      },
      options: {
        scales: {
          yAxes: [
            {
              ticks: {
                display: false,
                maxTicksLimit: 3,
              },
              gridLines: {
                display: false,
              },
            },
          ],
          xAxes: [
            {
              ticks: {
                fontColor: "#333",
                fontSize: 12,
                fontStyle: "normal",
                lineHeight: 1.5,
                fontFamily: "Noto Sans Kr",
              },
              gridLines: {
                display: false,
              },
              scaleLabel: {
                display: true,
              },
            },
          ],
        },
        layout: {
          padding: {
            top: 30,
            left: -100,
            bottom: -20,
          },
        },
        legend: {
          display: false,
        },
        tooltip: {
          enabled: false,
        },
        plugins: {
          datalabels: {
            anchor: "end",
            align: "top",
            offset: 0,
            color: "#1073ba",
            font: (context) => (isMiddle(context) ? { weight: "bold" } : undefined),
            formatter: function (value) {
              return value + "원";
            },
          },
        },
      },
      plugins: [ChartDataLabels],
    });
  },
};
</script>
