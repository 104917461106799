export const CONTACT_API = "/sendmail"; //## 문의하기
export const TERMS_PROTECTOR_API = "/terms/protector/agree"; //## 보호자 이용약관 버전 정보
export const POLICY_PROTECTOR_API = "/terms/protector/user"; //## 보호자 개인정보처리방침 버전 정보
export const TERMS_CAREGIVER_API = "/terms/caregiver/agree"; //## 간병인 이용약관 버전 정보
export const POLICY_CAREGIVER_API = "/terms/caregiver/user"; //## 간병인 개인정보처리방침 버전 정보
export const TERMS_LOCATION_API = "/terms/common/location"; //## 위치기반서비스 이용약관 정보
export const TERMS_API = "/terms"; //## 이용약관 상세
export const POLICY_API = "/terms"; //## 개인정보처리방침 상세

export default {
  CONTACT_API,
  TERMS_PROTECTOR_API,
  POLICY_PROTECTOR_API,
  TERMS_CAREGIVER_API,
  POLICY_CAREGIVER_API,
  TERMS_LOCATION_API,
  TERMS_API,
  POLICY_API,
};
