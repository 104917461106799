import "core-js/stable";
import "regenerator-runtime/runtime";
import "babel-polyfill";

import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import AOS from "aos";

import "aos/dist/aos.css";
import "@/assets/css/reset.css";
import "@/assets/css/common.css";
import "@/assets/css/pc.css";
import "@/assets/css/mobile.css";

Vue.config.productionTip = false;

new Vue({
  created() {
    AOS.init();
  },
  router,
  // store,
  // Vuex,
  render: (h) => h(App),
}).$mount("#app");
