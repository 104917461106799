<template>
  <main>
    <article class="subBan ban06">
      <div class="subBan__wrap">
        <h2>케어네이툰</h2>
        <p>환자와 간병인의 이야기, 웹툰으로 만나보세요</p>
      </div>
    </article>
    <section class="commu">
      <div class="basicWrap">
        <ul class="commu__squre clear">
          <li
            v-for="(item, itemIndex) in copyToonList.slice().reverse()"
            :key="itemIndex"
            @click="indexSave(itemIndex), enterDetail(item.episode)"
          >
            <a href="">
              <figure
                :style="`backgroundImage: url(${item.thumbnail})`"
              ></figure>
              <div class="commu__squre--tit">
                <p>{{ item.title }}</p>
                <span>{{ item.episode }}화</span>
              </div>
            </a>
          </li>
        </ul>
        <div class="commu__btn clear">
          <button
            type="button"
            v-for="pageItem in pageNum"
            :key="pageItem"
            @click="pageChange(pageItem)"
            :class="$route.query.page == pageItem ? 'active' : ''"
          >
            {{ pageItem }}
          </button>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import * as Utils from "../../constants/utils";
import toonList from "@/assets/js/toonList";
export default {
  data() {
    return {
      toonList: toonList, //## 리스트 데이터
      copyToonList: [], //## 리스트 데이터 복사본

      //페이지네이션
      curPage: 1, //## 현재 페이지
      pageNum: [1, 2], // 페이지네이션에 들어갈 숫자
      limit: Utils.osType() === "mobile" ? 8 : 12, // 한 페이지에 들어갈 아이템 수
      curItem: 0, // 현재 아이템

      defaultMsg: "일시적인 오류가 발생하였습니다. 잠시 후 다시 시도해주세요.",
    };
  },
  mounted() {
    // sessionStorage.setItem("changed", false);
    if (this.$route.query.page !== undefined) {
      this.curPage = this.$route.query.page;
    } else {
      // this.curPage = sessionStorage.getItem("newsPage");
    }
    this.getToon();
  },
  updated() {
    this.$router
      .push({
        name: "commuToon",
        query: {
          page: this.curPage,
        },
      })
      .catch(() => {});
  },
  watch: {
    $route: function () {
      if (this.$route.query.page != null) {
        // 페이지 렌더링
        this.curPage = this.$route.query.page;
        this.getToon();
      }
    },
  },
  methods: {
    getToon() {
      if (this.toonList !== null) {
        // 가져온 데이터가 null이 아닐 경우 리스트 아이템 자르기
        if (this.curPage == 1) {
          const startIndex = 16 - this.limit; // 시작 인덱스
          const endIndex = startIndex + this.limit; // 끝 인덱스
          this.copyToonList = this.toonList.slice(startIndex, endIndex);
        } else {
          const startIndex = 0; // 시작 인덱스
          const endIndex = 16 - this.limit; // 끝 인덱스
          this.copyToonList = this.toonList.slice(startIndex, endIndex);
        }
      }
    },
    // 페이지네이션
    pageChange(picked) {
      if (picked !== this.$route.query.page) {
        this.curPage = picked;
        // 리스트 아이템 자르기 및 페이지네이션 다시 렌더링
        this.$router
          .push({
            name: "commuToon",
            query: {
              page: this.curPage,
            },
          })
          .catch(() => {});
      } else {
        // console.log('same page');
      }
    },
    // 상세 페이지 이동
    indexSave(itemIndex) {
      sessionStorage.setItem("itemIndex", itemIndex);
    },
    enterDetail(pickedId) {
      this.$router.push({
        name: "commuToonDet",
        query: {
          contId: pickedId,
          page: this.curPage,
        },
      });
    },
  },
};
</script>

<style></style>
